<template>
  <div>
    <users-dialog v-model="showUserDialog" :id="idEdit" @setUser="setUser" :api="'/manager/users'" />
    <dialog-edit :value="value" @input="$emit('input')">
      <template v-slot:title>
        {{ data.name ? data.name : "Новый инвестор" }}
        <small v-if="id"> ({{ id }})</small>
      </template>
      <a-loader v-if="!loaded" />
      <v-row v-else class="mt-1 pt-2" style="overflow-y: auto; overflow-x: hidden">
        <v-col v-for="(el, i) in modelForm" :key="i" cols="12" :sm="el.size" class="py-0">
          <div v-for="name in el.name.split('+')" :key="name">
            <a-form-model
              :ref="'field_' + name"
              v-model="data"
              :model="getFormModel([name], model)"
              :errors="errors"
              :config="{ dense: true, readonly }"
              @validate="
                validate($event);
                doWatch($event);
              "
              @fieldData="setFieldData($event)"
            />
          </div>
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-btn v-if="$root.profile.role == 1000" color="lime accent-2" @click="showUserDialogFun(data.user_id)"
          ><btn-title icon="far fa-user">Пользователь</btn-title></v-btn
        >
        <v-spacer></v-spacer>
        <a-btn-save @click="submit()"></a-btn-save>
        <a-btn-delete v-if="id && getAccess('investor.delete')" @click="removeDialogShow = true"></a-btn-delete>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, genModel } from "../../components/mixings";

export default {
  components: {
    usersDialog: () => import("@/views/admin/dialogs/usersDialog2"),
  },
  mixins: [getForm, submitForm, removeEl, getAccess, genModel],
  props: {
    value: Boolean,
    id: Number,
    object_id: Number,
    defaults: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.investors,
      model: this.$store.getters["config/get"].models.investors.form,
      api: "/mechti/investors",
      removeDialogShow: false,
      showUserDialog: false,
      idEdit: 0,
      loaded: false,
    };
  },
  computed: {
    readonly() {
      return true;
    },
  },
  watch: {
    value() {
      if (this.value) {
        this.updateData(this.id);
      }
    },
  },
  methods: {
    afterFetchData(r) {
      let t = 0;
      if (this.m?.config?.watch) {
        t = this.data?.[this.m.config.watch] || 0;
      }
      this.getModel("default");
      this.loaded = true;
    },
    getModel(t) {
      let config;
      if (t) config = JSON.parse(JSON.stringify(this.m?.config?.[t] || {}));
      let model = this.calcModelConfig(config);
      if (true)
        model.forEach((el) => {
          if (el.hiddenCreate && !this.data?.id) {
            el.hidden = true;
          }
        });
      this.fillFormFromModel(model);
      this.model = model;
    },
    async submit() {
      if (await this.validateAll(this.data)) {
        if (this.name_ !== this.data.name) {
          const resp = await this.$axios.get(this.api, {
            params: {
              filters: { name: this.data.name },
            },
          });
          let status = resp.data.status == "ok";
          if (!status) {
            this.$root.$emit("show-error", {
              text: "Ошибка....",
            });
            return;
          }
          if (resp.data.data.length) {
            this.errors.name = "Имя занято";
            return;
          }
        }
        await this.post(this.data, this.api);
        return true;
      }
      return false;
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
      this.$router.push({ name: "investors" });
      this.$store.dispatch("dirs/fitchDirs", "investor");
    },
    afterSave(data, status) {
      this.$store.dispatch("dirs/fitchDirs", "investor");
      if (status) this.$emit("input");
      // this.$router.push({name: "objects_view", params: {id: this.id}});
    },
    showUserDialogFun() {
      this.idEdit = this.data.user_id || 0;
      this.showUserDialog = true;
      console.log(this.idEdit, this.showUserDialog);
    },
    async setUser(e) {
      console.log("setuser", e);
      if (e.id == this.data.user_id) return;
      let status = false;
      this.loading = true;

      let data = { id: this.data.id, user_id: e.id };
      if (this.id) {
        let response = await this.$axios.post(this.api, data);
        status = response.data.status == "ok";
      } else status = true;
      this.loading = false;
      if (status) {
        this.data.user_id = e.id;
        this.data.user_name = e.login;
        this.$root.$emit("show-info", {
          text: "Пользователь привязан",
        });
      } else {
        this.$root.$emit("show-error", {
          text: "Ошибка привязки пользователя",
        });
      }
    },
  },
};
</script>
